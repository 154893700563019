import { Injectable, Inject } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from './localStorage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUserToken } from '../models/IUser';
//import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class JwtdecodeService {

  constructor(private localStorageService: LocalStorageService,
    private jwtHelper: JwtHelperService) { }

  //   hasPermission(permissionName, facilityId) {
  //     const token = this.sdkStorageService.get(LocalStorageConstants.ACCESS_TOKEN, 'webStorageService');
  //     const decodedToken = jwt_decode(token);
  //     var facilityRole = _.find(JSON.parse(decodedToken.FacilityRoles), { "FacilityID": facilityId });
  //     if (decodedToken.UserType == "SuperAdmin" || decodedToken.UserType == "Admin" || facilityRole.RoleName == "FacilityAllAccess"
  //     || decodedToken.UserType == "PartnerSuperAdmin") {
  //       return true;
  //     }
  //     if (facilityRole) {
  //       var roleId = facilityRole.RoleID;
  //       var permissionArray = _.find(JSON.parse(decodedToken.Permissions), { "RoleID": roleId });
  //       var checkPermission = _.find(permissionArray.Permissions, { "PermissionName": permissionName });
  //       if (checkPermission) {
  //         return true;
  //       } else{
  //         return false;
  //       }
  //     }
  //     return false;
  //   }

  isSuperAdmin() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'SuperAdmin';
  }

  isAdmin() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'Admin';
  }

  isUserTypeEqualsUser() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'Member';
  }

  isTokenExpired() {
    const token = this.localStorageService.get('token');

    if (this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    return false;
  }

  getUserDetails() {
    const token = this.localStorageService.get('token');
    const userAccessToken: any = jwt_decode(token);

    let user: IUserToken = {
      Email: userAccessToken.sub,
      Name: userAccessToken.name,
      Role: userAccessToken.userRole,
      UserId: userAccessToken.UID
    };
    return user;
  }
}

export interface IToken {
  sub: string,
  userRole: string,
  exp: number
}